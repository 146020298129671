var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container project-type-editor"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(_vm._s(_vm.editing ? "Editar tipo de proyecto" : "Nuevo tipo de proyecto"))])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(_vm._s(_vm.editing ? "Datos del tipo de proyecto" : "Datos del nuevo tipo de proyecto")+" ")])]),_c('b-card-body',[_c('form',{staticClass:"col-12 custom-form custom-form--pusher",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('section',{staticClass:"form-content"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descripción *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.description),expression:"form.description",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.form.description.$error
                    },attrs:{"id":"description","type":"text","autocomplete":"off","maxlength":"100","placeholder":"Ingresar descripción"},domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      _vm.$v.form.description.$error &&
                      !_vm.$v.form.description.required
                    )?_c('FormError',{attrs:{"message":"Descripción es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"prefix"}},[_vm._v("Prefijo *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.form.prefix),expression:"form.prefix",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error':
                        _vm.$v.form.prefix.$error
                    },attrs:{"id":"prefix","type":"text","autocomplete":"off","maxlength":"5","placeholder":"Ingresar prefijo"},domProps:{"value":(_vm.form.prefix)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "prefix", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      _vm.$v.form.prefix.$error &&
                      !_vm.$v.form.description.required
                    )?_c('FormError',{attrs:{"message":"Prefijo es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'config-project-type-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? 'Volver' : 'Cancelar')+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])])])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }