<template>
  <b-modal v-model="showLocal" @hidden="hidden" centered no-close-on-esc no-close-on-backdrop hide-footer hide-header content-class="warning-modal">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between">
        <div class="modal-title-left" >Borrar datos</div>
        <b-button
                class="icon-close icon-close-default"
                @click="hideModal" aria-label="Close"
                >
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 mt-4 text-center">
        <div class="my-1 d-flex justify-content-center align-items-center warning-text"> <i class="icon-warning"></i>Todos los datos seleccionados se borrarán de manera permanente</div>
          <div class="d-flex justify-content-end mt-3">
            <span class="mt-3 mb-2 mx-1 button button-light" @click="hideModal">
              Cancel
            </span>
            <b-button class="mt-3 mb-2 mx-1 button button-delete"  @click="confirm">
              Borrar
            </b-button>
          </div>
        </div>
      </div>
  </b-modal>
</template>
<script>
import {ModalMixin} from "wize-admin";

export default {
  name: 'WarningDeleteModal',
  mixins: [ModalMixin],
  methods: {
    confirm () {
      this.$emit('confirm')
    }
  },
}
</script>

<style lang="stylus" scoped>


</style>
