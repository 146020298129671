import ConfigView from "../ConfigView.vue";
import PusherEditor from "../Pages/PusherEditor.vue";
import PusherList from "../Pages/PusherList.vue";
import PusherDetails from "../Pages/PusherDetails.vue";
import RejectReasonEditor from "../Pages/RejectReasonEditor.vue";
import RejectReasonList from "../Pages/RejectReasonList.vue";
import ProjectTypeEditor from "../Pages/ProjectTypeEditor.vue";
import ProjectTypeList from "../Pages/ProjectTypeList.vue";
import ZonalEditor from "../Pages/ZonalEditor.vue";
import ZonalList from "../Pages/ZonalList.vue";

const routes = [
  {
    path: '/config',
    component: ConfigView,
    roles: ['administrator'],
    name: 'config',
    children: [
      {
        path: 'pusher',
        name: 'config-pusher-list',
        component: PusherList,
      },
      {
        path: 'pusher/create',
        name: 'config-pusher-create',
        component: PusherEditor,
      },
      {
        path: 'pusher/:pusherId',
        name: 'config-pusher-edit',
        component: PusherEditor
      },
      {
        path: 'pusher/:pusherId/details',
        name: 'config-pusher-details',
        component: PusherDetails
      },
      {
        path: 'reject-reason',
        name: 'config-reject-reason-list',
        component: RejectReasonList
      },
      {
        path: 'reject-reason/create',
        name: 'config-reject-reason-create',
        component: RejectReasonEditor
      },
      {
        path: 'reject-reason/:id',
        name: 'config-reject-reason-edit',
        component: RejectReasonEditor
      },
      {
        path: 'project-type',
        name: 'config-project-type-list',
        component: ProjectTypeList
      },
      {
        path: 'project-type/create',
        name: 'config-project-type-create',
        component: ProjectTypeEditor
      },
      {
        path: 'project-type/:id',
        name: 'config-project-type-edit',
        component: ProjectTypeEditor
      },
      {
        path: 'zonal',
        name: 'config-zonal-list',
        component: ZonalList
      },
      {
        path: 'zonal/create',
        name: 'config-zonal-create',
        component: ZonalEditor
      },
      {
        path: 'zonal/:id',
        name: 'config-zonal-edit',
        component: ZonalEditor
      },
    ]
  },
]

export default routes
