<template>
  <b-modal
    v-model="showLocal"
    @hidden="hidden"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="status-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header modal-header-status
              d-flex
              justify-content-center
              align-items-center
              position-relative
            "
          >
            <div class="modal-title modal-title-lg">¡Éxito!</div>
            <b-button
              class="icon-close icon-close-green"
              @click="hideModal"
              aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content pt-0">
          <p class="modal-description">
            ¡La operación fue realizada con éxito!
          </p>

          <button class="button button-primary" @click="close()">OK</button>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { ModalMixin } from "wize-admin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "WarningDeleteModal",
  data() {
    return {
      reasonId: null,
      reasons: [],
    };
  },
  mixins: [ModalMixin],
  methods: {
    confirm() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      this.$emit("confirm");
    },
    close() {
      this.$emit("hide");
    },
  },
  validations: {
    reasonId: { required },
  },
};
</script>

<style lang="stylus" scoped></style>