<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Cargar abonos</h1>
    </div>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8">
          <b-card
            tag="article"
            no-body
            style="max-width: 640px"
            class="custom-card"
          >
            <b-card-header
              header-tag="section"
              class="custom-card-header d-flex justify-content-between align-items-center"
            >
              <h3>Carga de abonos</h3>
            </b-card-header>
            <b-card-body class="custom-card-body">
              <info-badge
                text="Seleccione los siguientes campos para poder cargar sus archivos."
              >
                <template slot="icon">
                  <WarningAlertIcon class="mr-2 alert-icon" />
                </template>
              </info-badge>
              <form
                class="custom-form custom-form--payments payments-form"
                @submit.prevent="save"
                novalidate
              >
                <div class="custom-form">
                  <div class="form-group">
                    <label for="operationNumber">N° de operación *</label>
                    <input
                      id="operationNumber"
                      type="text"
                      v-model.trim="operationNumber"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="Número de operación"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error': $v.operationNumber.$error,
                      }"
                    />
                    <FormError
                      v-if="
                        $v.operationNumber.$error &&
                        !$v.operationNumber.required
                      "
                      message="Número de operación es requerido"
                    />
                  </div>
                  <div class="form-group">
                    <label for="paymentDate">Fecha de abono *</label>

                    <FormDatepicker
                      label-id="paymentDate"
                      :show-calendar="showPaymentDate"
                      :max="new Date()"
                      @show="showPaymentDate = true"
                      @hidden="showPaymentDate = false"
                      v-model="paymentDate"
                    />
                    <FormError
                      v-if="
                        $v.paymentDate.$error &&
                        !$v.paymentDate.required
                      "
                      message="Fecha de abono es requerida"
                    />
                  </div>
                  <div class="form-group">
                    <label for="payrollNumber">N° de planilla *</label>
                    <input
                      id="payrollNumber"
                      type="text"
                      v-model.trim="payrollNumber"
                      autocomplete="off"
                      maxlength="50"
                      placeholder="N° de planilla"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error': $v.payrollNumber.$error,
                      }"
                    />
                    <FormError
                      v-if="
                        $v.payrollNumber.$error && !$v.payrollNumber.required
                      "
                      message="Número de planilla es requerido"
                    />
                  </div>
                  <div class="form-group">
                    <label for="payrollDesc">Descripción de planilla</label>
                    <input
                      id="payrollDesc"
                      type="text"
                      v-model.trim="payrollDesc"
                      autocomplete="off"
                      maxlength="100"
                      placeholder="Descripción de planilla"
                      class="custom-form-control"
                    />
                  </div>
                </div>

                <section class="file-container">
                  <label for="file">Cargar excel de abonos *</label>
                  <FileInput
                    class="w-100 mt-2"
                    id-file="file"
                    :acceptFile="['.xlsx']"
                    @onChange="onFileChange"
                  />
                  <FormError
                    v-if="$v.file.$error && !$v.file.required"
                    message="El archivo es requerido"
                  />
                </section>

                <section class="file-container">
                  <label for="records">Cargar constancias</label>
                  <FileInput
                    class="w-100 mt-2"
                    :multiple="true"
                    id-file="records"
                    :acceptFile="['.xlsx', '.pdf']"
                    @onChange="onRecordsChange"
                  />
                </section>

                <div class="form-group-buttons text-right">
                  <router-link
                    :to="{ name: 'payments-made-batch-list' }"
                    class="button button-light"
                  >
                    Cancelar
                  </router-link>
                  <button class="button button-primary" type="submit">
                    Procesar
                  </button>
                </div>
                <div class="form-errors mt-3">
                  <b
                    ><span class="error" v-if="errors.length > 0"
                      >Errores: {{ errors.length }}</span
                    ></b
                  ><br/>
                  <span
                    v-for="(e, i) in errors"
                    class="file-error"
                    :key="`${i}-error`"
                    >{{ e }}<br
                  /></span>
                </div>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Alert,
  FileInput,
  FormError,
  FormDatepicker,
  InfoBadge,
  WarningAlertIcon
} from "wize-admin";

import { required } from "vuelidate/lib/validators";

import { PaymentMadeBatchService } from "@/core/services";

export default {
  components: {
    InfoBadge,
    FileInput,
    FormError,
    FormDatepicker,
    WarningAlertIcon
  },
  name: "PaymentBatchUpload",
  data() {
    return {
      operationNumber: null,
      paymentDate: null,
      payrollNumber: null,
      payrollDesc: null,
      file: null,
      records: [],
      errors: [],
      showPaymentDate: false,
    };
  },
  methods: {
    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      await this.$store.dispatch("app/loading", true);
      this.errors = [];
      const data = {
        operationNumber: this.operationNumber,
        paymentDate: this.paymentDate,
        payrollNumber: this.payrollNumber,
        payrollDesc: this.payrollDesc,
        file: this.file,
      };
      if (this.records && this.records.length > 0) data.records = this.records;
      try {
        Object.keys(data).forEach(key => {
          if (data[key] === null || typeof data[key] === 'undefined') {
            console.log(key)
            delete data[key];
          }
        });

        const resp = await PaymentMadeBatchService.uploadBatch(data);
        if (resp.payload.messages && resp.payload.messages.length > 0) {
          this.errors = resp.payload.messages;
          return;
        }
        Alert.success("Se cargaron los abonos correctamente");
        this.$router.push({ name: "payments-made-batch-list" });
      } catch (e) {
        this.errors = [];
        if (e.errors && e.errors.message) this.errors.push(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    onFileChange(files) {
      this.$v.file.$reset();
      if(Array.isArray(files)){
        this.file = files[0];
      }else{
        this.file = files;
      }
    },
    onRecordsChange(files) {
      if(Array.isArray(files)){
        this.records = files[0];
      }else{
        this.records = files;
      }
    },
  },
  validations: {
    operationNumber: { required },
    paymentDate: { required },
    payrollNumber: { required },
    file: { required },
  },
  async created() {},
};
</script>

<style lang="stylus" scoped>
@import '../Styles/payment-batch-upload';
</style>
