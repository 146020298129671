import {http} from '@/http'

export default {
  list(data, params) {
    return http.post('/payments/list', data, {params})
  },
  download(data) {
    return http.post('/payments/xlsx', data,  {responseType: 'blob'})
  },
  downloadMacroBank(data) {
    return http.post('/payments/macrobank/xlsx', data,  {responseType: 'blob'})
  },
  getPayment(id) {
    return http.get(`/payments/${id}`)
  },
  listVouchers(data, params) {
    return http.post('/payments/vouchers/list',data, {params})
  },
  downloadVouchers(data) {
    return http.post('/payments/vouchers/xlsx', data, {responseType: 'blob'})
  },
  changeStatus(paymentId, data){
    return http.put(`/payments/${paymentId}/status`, data)
  },
}
