<template>
  <div class="page-container reject-reason-list">
    <div class="page-title-container">
      <h1 class="page-title">Comprobantes electrónicos</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar comprobantes</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <!-- <div class="table-filters position-relative d-flex">
          <div class="d-flex align-items-start justify-content-between ml-2">
            <button
              type="button"
              class="
                d-flex
                align-items-center
                justify-content-between
                ml-2
                border-0
                filters-button
              "
              @click="showFilters = !showFilters"
            >
              <div class="icon-filter"></div>
              <div>Filtrar comprobantes</div>
            </button>
          </div>
          <div class="d-flex flex-wrap align-items-center w-100 ml-2 mr-2">
            <span
              v-for="(val, name) in filters"
              v-bind:key="name"
              class="
                d-flex
                justify-content-between
                ml-2
                mb-1
                filters-button-secondary
              "
            >
              {{ getFilterName(name, val) }}
              <div class="icon-delete ml-3" @click="deleteFilter(name)"></div
              ></span>
          </div>
          <filter-menu
            :show="showFilters"
            @hide="showFilters = false"
            :options="filterOptions"
            :selectedOptionFilter="filters"
            @setFilters="setFilters"
          ></filter-menu>
        </div> -->

        <section class="d-flex align-items-center">
          <ButtonWithTooltip
            text="Actualizar"
            tooltip-variant="refresh-tooltip"
            @click="loadData"
            class="m-0"
          >
            <template slot="icon">
              <RefreshTableIcon />
            </template>
          </ButtonWithTooltip>

          <ButtonWithTooltip
            text="Descargar plantilla"
            @click="downloadReport"
            class="mr-3"
            tooltip-variant="download-template"
          >
            <template slot="icon">
              <DownloadTableIcon />
            </template>
          </ButtonWithTooltip>

          <SearchBar
            placeholder="Buscar por RUC, razón social o número de factura"
            @search="onSearch"
            class="search-bar"
          />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-center align-items-center">
                  <span class="d-inline-block pt-1">Fecha</span>
                </div>
              </th>
              <th>RUC</th>
              <th>Razón social</th>
              <th>Número de factura</th>
              <th>Forma de pago</th>
              <th>Fecha de pago</th>
              <th>Monto</th>
              <th>Monto a pagar</th>
              <th>Descargar XML</th>
              <th>Descargar PDF</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center" v-local-date="o.voucher.issueDate"></td>
              <td class="cell-center">{{ o.voucher.supplierId }}</td>
              <td class="cell-center">{{ o.voucher.supplierName }}</td>
              <td class="cell-center">{{ o.voucher.voucherCode }}</td>
              <td class="cell-center">{{ o.voucher.paymentTerm }}</td>
              <td class="cell-center" v-local-date="o.paymentDate"></td>
              <td class="cell-center" v-decimal:pen="o.voucher.total"></td>
              <td
                class="cell-center"
                v-decimal:pen="o.voucher.payableAmount"
              ></td>
              <td class="cell-center">
                <b-button
                  type="none"
                  class="bg-transparent p-0 border-0"
                  @click="download(o.voucher.xmlAsset)"
                >
                  <XmlIcon />
                </b-button>
              </td>
              <td class="cell-center">
                <b-button
                  type="none"
                  class="bg-transparent p-0 border-0"
                  @click="download(o.voucher.pdfAsset)"
                >
                  <PdfIcon />
                </b-button>
              </td>
              <td class="cell-center">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable
                  v-if="
                    o.status === PaymentStatus.REVIEW.key ||
                    o.status === PaymentStatus.REJECTED.key
                  "
                >
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Aceptar comprobante"
                      @click="
                        changeStatus(o.id, {
                          status: PaymentStatus.APPROVED.key,
                        })
                      "
                      v-if="o.status === PaymentStatus.REVIEW.key"
                    />
                    <DropdownItem
                      text="Rechazar comprobante"
                      @click="openReasonModal(o.id)"
                      v-if="o.status === PaymentStatus.REVIEW.key"
                    />
                    <DropdownItem
                      text="Ver motivo de rechazo"
                      @click="seeRejectReason(o.rejectReason)"
                      v-if="o.status === PaymentStatus.REJECTED.key"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>

    <ReasonForRejection
      :payment-id="selectedPaymentId"
      :reject-reasons="rejectReasons"
      :show="showReasonsModal"
      @hide="showReasonsModal = false"
      @confirm="changeStatus"
    />
    <RejectReasonModal
      :show="showRejectReasonModal"
      @hide="showRejectReasonModal = false"
      :reject-reason="selectedRejectReason"
    />
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  PaginationMixin,
  FilterMenu,
  FiltersIcon,
  DeleteFilterIcon,
  FilterMixin,
  SearchBar,
  DropdownTable,
  DropdownItem,
  ButtonWithTooltip,
  DownloadTableIcon,
  PdfIcon,
  XmlIcon,
  RefreshTableIcon,
} from "wize-admin";

import * as moment from "moment";

import { Constants as LocalConstants } from "@/core/utils";
import { PaymentService, RejectReasonService } from "@/core/services";
import { RejectReasonModal } from "@/core/components";
import ReasonForRejection from "../Components/ReasonForRejection";
import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    ButtonWithTooltip,
    ReasonForRejection,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
    RejectReasonModal,
    DownloadTableIcon,
    PdfIcon,
    XmlIcon,
    RefreshTableIcon,
  },
  data() {
    return {
      list: [],
      PaymentStatus: LocalConstants.PaymentStatus,
      showRejectReasonModal: false,
      selectedRejectReason: null,
      tooltipType: null,
      showReasonsModal: false,
      rejectReasons: [],
      selectedPaymentId: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.PaymentStatus.PENDING,
            LocalConstants.PaymentStatus.REVIEW,
            LocalConstants.PaymentStatus.APPROVED,
            LocalConstants.PaymentStatus.REJECTED,
            LocalConstants.PaymentStatus.PAID,
            LocalConstants.PaymentStatus.INCORRECT,
          ],
        },
        {
          label: "Fecha",
          key: "date",
          type: Constants.TypeFilters.DATE,
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        let filters = JSON.parse(JSON.stringify(this.filters));

        if (filters.date && filters.date.selectedDate) {
          filters.dateStart = moment(filters.date.startDate).format(
            "YYYY-MM-DD"
          );
          filters.dateEnd = moment(filters.date.endDate).format("YYYY-MM-DD");

          delete filters.date;
        }

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "voucher.issueDate,desc",
        };
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.listVouchers(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.downloadVouchers(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    openReasonModal(paymentId) {
      this.selectedPaymentId = paymentId;
      this.showReasonsModal = true;
    },
    changeStatus(paymentId, data) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del pago?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await PaymentService.changeStatus(paymentId, data))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
    try {
      const reasons = (await RejectReasonService.listActive()).payload;
      reasons.forEach((b) =>
        this.rejectReasons.push({ key: b.id, label: b.description })
      );
    } catch (e) {
      console.error(e);
    }
  },
};
</script>

<style lang="stylus" scoped></style>
