<template>
  <section class="dashboard-container">
      <h1 class="dashboard-title">Control de pagos</h1>
    <div class="dashboard-card">
    </div>
  </section>
</template>

<script>

export default {
  name: "DashboardPage",
  components: {},
  data() {
    return {}
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
  @import "../Styles/Dashboard.styl"

</style>
