var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v(" "+_vm._s(_vm.editing ? "Editar proveedor" : "Nuevo proveedor")+" ")])]),_c('div',{staticClass:"container px-0"},[_c('div',{staticClass:"row justify-content-center"},[_c('form',{staticClass:"col-12 custom-form custom-form--supplier",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"760px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v(" "+_vm._s(_vm.editing ? "Datos del proveedor" : "Datos del nuevo proveedor")+" ")])]),_c('b-card-body',{staticClass:"custom-card-body card-border"},[_c('section',[_c('h2',{staticClass:"form-subtitle"},[_vm._v("Datos personales")]),_c('p',{staticClass:"form-description"},[_vm._v(" Ingresa los datos personales del proveedor ")])]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyId"}},[_vm._v("RUC *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.companyId),expression:"supplier.companyId",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.companyId.$error || _vm.companyIdExists,
                  },attrs:{"id":"companyId","type":"text","maxlength":"11","autocomplete":"off","placeholder":"Ingresar número de RUC"},domProps:{"value":(_vm.supplier.companyId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "companyId", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.companyIdExists)?_c('FormError',{attrs:{"message":"Ya existe un proveedor registrado con el mismo RUC"}}):_vm._e(),(_vm.$v.supplier.companyId.$error && !_vm.$v.supplier.companyId.required)?_c('FormError',{attrs:{"message":"RUC es requerido"}}):_vm._e(),(_vm.$v.supplier.companyId.$error && !_vm.$v.supplier.companyId.numeric)?_c('FormError',{attrs:{"message":"RUC debe tener un valor numérico"}}):_vm._e(),(_vm.$v.supplier.companyId.$error && !_vm.$v.supplier.companyId.minLength)?_c('FormError',{attrs:{"message":"RUC debe tener 11 dígitos"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyName"}},[_vm._v("Razón social *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.companyName),expression:"supplier.companyName",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.companyName.$error,
                  },attrs:{"id":"companyName","type":"text","maxlength":"200","autocomplete":"off","placeholder":"Ingresar razón social"},domProps:{"value":(_vm.supplier.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "companyName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.supplier.companyName.$error &&
                    !_vm.$v.supplier.companyName.required
                  )?_c('FormError',{attrs:{"message":"Número de documento es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"custom-form-row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyAddress"}},[_vm._v("Domicilio fiscal *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.companyAddress),expression:"supplier.companyAddress",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.supplier.companyAddress.$error,
                  },attrs:{"id":"companyAddress","type":"text","autocomplete":"off","placeholder":"Ingresar domicilio fiscal"},domProps:{"value":(_vm.supplier.companyAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "companyAddress", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.supplier.companyAddress.$error &&
                    !_vm.$v.supplier.companyAddress.required
                  )?_c('FormError',{attrs:{"message":"Domicilio fiscal es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"custom-form-row"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyAddress"}},[_vm._v("Nombre de contacto *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.contactName),expression:"supplier.contactName",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.supplier.contactName.$error,
                  },attrs:{"id":"contactName","type":"text","autocomplete":"off","placeholder":"Nombre de contacto"},domProps:{"value":(_vm.supplier.contactName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "contactName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.supplier.contactName.$error &&
                    !_vm.$v.supplier.contactName.required
                  )?_c('FormError',{attrs:{"message":"Nombre de contacto es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone"}},[_vm._v("Teléfono")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.phone),expression:"supplier.phone",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.phone.$error,
                  },attrs:{"id":"phone","type":"text","maxlength":"9","autocomplete":"off","placeholder":"Ingresar teléfono"},domProps:{"value":(_vm.supplier.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "phone", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.supplier.phone.$error && !_vm.$v.supplier.phone.numeric)?_c('FormError',{attrs:{"message":"Telefóno debe ser un valor numérico"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"mobile"}},[_vm._v("Celular")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.mobile),expression:"supplier.mobile",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.mobile.$error,
                  },attrs:{"id":"mobile","type":"text","autocomplete":"off","placeholder":"Ingresar celular","maxlength":"9"},domProps:{"value":(_vm.supplier.mobile)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "mobile", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.supplier.mobile.$error && !_vm.$v.supplier.mobile.numeric)?_c('FormError',{attrs:{"message":"Celular debe ser un valor numérico"}}):_vm._e(),(_vm.$v.supplier.mobile.$error && !_vm.$v.supplier.mobile.mobile)?_c('FormError',{attrs:{"message":"Ingresa un celular válido de 9 dígitos"}}):_vm._e()],1)]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Correo electrónico")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.email),expression:"supplier.email",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.email.$error,
                  },attrs:{"id":"email","type":"text","autocomplete":"off","placeholder":"Ingresar correo electrónico"},domProps:{"value":(_vm.supplier.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "email", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.supplier.email.$error && !_vm.$v.supplier.email.email)?_c('FormError',{attrs:{"message":"Ingresa un correo electrónico válido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"detractionAccount"}},[_vm._v("Cuenta de detracciones *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.detractionAccount),expression:"supplier.detractionAccount",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.supplier.detractionAccount.$error,
                  },attrs:{"id":"detractionAccount","type":"text","autocomplete":"off","maxlength":"20","placeholder":"Ingresar cuenta de detracciones"},domProps:{"value":(_vm.supplier.detractionAccount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "detractionAccount", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.supplier.detractionAccount.$error &&
                    !_vm.$v.supplier.detractionAccount.numeric
                  )?_c('FormError',{attrs:{"message":"Cuenta de detracciones debe tener un valor numérico"}}):_vm._e(),(
                    _vm.$v.supplier.detractionAccount.$error &&
                    !_vm.$v.supplier.detractionAccount.required
                  )?_c('FormError',{attrs:{"message":"Cuenta de detracciones es requerida"}}):_vm._e()],1)]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"managementType"}},[_vm._v("Tipo de gestión contable *")]),_c('FormSelect',{attrs:{"id":"managementType","items":_vm.managementTypes,"defaultOption":"Elegir tipo de gestión contable","showError":_vm.$v.supplier.managementType.$error},model:{value:(_vm.supplier.managementType),callback:function ($$v) {_vm.$set(_vm.supplier, "managementType", $$v)},expression:"supplier.managementType"}}),(
                    _vm.$v.supplier.managementType.$error &&
                    !_vm.$v.supplier.managementType.required
                  )?_c('FormError',{attrs:{"message":"Tipo de gestión contable es requerido"}}):_vm._e()],1)])]),_c('b-card-body',{staticClass:"custom-card-body pt card-border"},[_c('section',[_c('h2',{staticClass:"form-subtitle"},[_vm._v("Datos bancarios")]),_c('p',{staticClass:"form-description"},[_vm._v(" Ingresa los datos de la cuenta bancaria donde el proveedor recibirá sus abonos ")])]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"bankHolder"}},[_vm._v("Nombre del titular")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.companyName),expression:"supplier.companyName",modifiers:{"trim":true}}],staticClass:"custom-form-control",attrs:{"id":"bankHolder","maxlength":"200","disabled":"","type":"text","autocomplete":"off","placeholder":"Ingresar nombre del titular"},domProps:{"value":(_vm.supplier.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "companyName", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"bank"}},[_vm._v("Banco")]),_c('FormSelect',{attrs:{"id":"bank","items":_vm.banks,"defaultOption":"Elegir banco"},model:{value:(_vm.supplier.bankId),callback:function ($$v) {_vm.$set(_vm.supplier, "bankId", $$v)},expression:"supplier.bankId"}})],1)]),_c('div',{staticClass:"custom-form-row custom-form-row--col-2"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"bankAccount"}},[_vm._v("Número de cuenta bancaria")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.bankAccount),expression:"supplier.bankAccount",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error': _vm.$v.supplier.bankAccount.$error,
                  },attrs:{"id":"bankAccount","type":"text","maxlength":"20","autocomplete":"off","placeholder":"Ingresar número de cuenta bancaria"},domProps:{"value":(_vm.supplier.bankAccount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "bankAccount", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                    _vm.$v.supplier.bankAccount.$error &&
                    !_vm.$v.supplier.bankAccount.numeric
                  )?_c('FormError',{attrs:{"message":"Número de cuenta bancaria debe tener un valor numérico"}}):_vm._e(),(
                    _vm.$v.supplier.bankAccount.$error &&
                    !_vm.$v.supplier.bankAccount.required
                  )?_c('FormError',{attrs:{"message":"Número de cuenta bancaria es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group mb-0"},[_c('label',{attrs:{"for":"numberCci"}},[_vm._v("Número de CCI")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.supplier.bankInterAccount),expression:"supplier.bankInterAccount",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                    'custom-form-control-error':
                      _vm.$v.supplier.bankInterAccount.$error,
                  },attrs:{"id":"numberCci","type":"text","maxlength":"20","autocomplete":"off","placeholder":"Ingresar número de CCI"},domProps:{"value":(_vm.supplier.bankInterAccount)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.supplier, "bankInterAccount", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                  _vm.$v.supplier.bankInterAccount.$error &&
                  !_vm.$v.supplier.bankInterAccount.numeric
                )?_c('FormError',{attrs:{"message":"Número de CCI debe tener un valor numérico"}}):_vm._e(),(_vm.$v.supplier.bankInterAccount.$error && !_vm.$v.supplier.bankInterAccount.minLength)?_c('FormError',{attrs:{"message":"Número de CCI debe tener 20 dígitos"}}):_vm._e(),(
                    _vm.$v.supplier.bankInterAccount.$error &&
                    !_vm.$v.supplier.bankInterAccount.required
                  )?_c('FormError',{attrs:{"message":"Número de CCI es requerido"}}):_vm._e()],1)]),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'supplier-list' }}},[_vm._v(" "+_vm._s(_vm.editing ? "Volver" : "Cancelar")+" ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])],1)])],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }