import PaymentsView from "../PaymentsView.vue";
import PaymentList from "../Pages/PaymentList.vue";
import PaymentBatchList from "../Pages/PaymentBatchList.vue";
import PaymentBatchUpload from "../Pages/PaymentBatchUpload.vue";
import PaymentMadeList from "../Pages/PaymentMadeList.vue";
import PaymentMadeUpload from "../Pages/PaymentMadeUpload.vue";

const routes = [
  {
    path: '/payments',
    component: PaymentsView,
    roles: ['administrator'],
    name: 'payments',
    children: [
      {
        path: 'list',
        name: 'payments-list',
        component: PaymentList
      },
      {
        path: 'batch',
        name: 'payments-batch-list',
        component: PaymentBatchList
      },
      {
        path: 'batch/upload',
        name: 'payments-batch-upload',
        component: PaymentBatchUpload
      },
      {
        path: 'made',
        name: 'payments-made-batch-list',
        component: PaymentMadeList
      },
      {
        path: 'made/upload',
        name: 'payments-made-upload',
        component: PaymentMadeUpload
      },
    ]
  },
]

export default routes
