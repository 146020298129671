<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Carga de abonos</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar cargas</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <section class="d-flex align-items-center">
          <SearchBar
            placeholder="Buscar por nombre de archivo"
            @search="onSearch"
            class="search-bar"
          />

          <b-button
            :to="{ name: 'payments-made-upload' }"
            variant="none"
            class="button button-primary button-table-action"
            >Cargar
          </b-button>
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-center align-items-center">
                  <span class="d-inline-block pt-1">Fecha de carga</span>
                </div>
              </th>
              <th>Nombre de archivo</th>
              <th>Cantidad de abonados</th>
              <th>N° de operación</th>
              <th>Fecha de abono</th>
              <th>N° de planilla</th>
              <th>Descripción de planilla</th>
              <th>Cargado por</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center pl-0" v-local-date="o.uploadDate"></td>
              <td class="cell-center">{{ o.file.name }}</td>
              <td class="cell-center" v-int="o.paymentsCount"></td>
              <td class="cell-center">{{ o.operationNumber }}</td>
              <td class="cell-center pl-0" v-local-date="o.paymentDate"></td>
              <td class="cell-center">{{ o.payrollNumber }}</td>
              <td class="cell-center">{{ o.payrollDesc }}</td>
              <td class="cell-center">{{ o.createdBy }}</td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Descargar archivo"
                      @click="download(o.file)"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  Constants,
  Util,
  Alert,
  DeleteFilterIcon,
  FilterMenu,
  FilterMixin,
  FiltersIcon,
  PaginationMixin,
  DropdownTable,
  DropdownItem,
  SearchBar,
} from "wize-admin";

import { PaymentMadeBatchService } from "@/core/services";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
  },
  data() {
    return {
      list: [],
      tooltipType: null,
      filterOptions: [
        {
          label: "Fecha de carga",
          key: "uploadDate",
          type: Constants.TypeFilters.DATE,
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        let filters = { ...this.filters };
        filters = {
          ...filters,
          ...Util.getDateRangeFilters(filters.uploadDate, "upload"),
        };
        delete filters.uploadDate;

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "createdAt,desc",
          search: this.search,
        };
        const data = { search: this.search, ...filters };
        const response = await PaymentMadeBatchService.list(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  mounted() {
    this.loadData(this.page, this.pageSize);
  },
};
</script>

<style lang="stylus" scoped></style>
