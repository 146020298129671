<template>
  <nav class="col-12 navbar nav-header py-0">
    <div class="col-12 d-flex align-items-center justify-content-end">
      <UserDropdown :user="user" @logout="logout()" />
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import { UserDropdown } from "wize-admin";

export default {
  name: "PageHeader",
  components: {
    UserDropdown,
  },
  data() {
    return {
      showCart: false,
      categories: [],
      subcategories: [],
    };
  },
  methods: {
    logout() {
      this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.push({ name: "login" }));
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
};
</script>

<style scoped lang="stylus">
</style>
