<template>
  <b-modal
    v-model="showLocal"
    @hidden="hide"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    hide-header
    id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
            class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title text-white">Motivo de rechazo</div>
            <b-button
              class="icon-close"
              @click="hide"
              aria-label="Close"
            >
              <CloseModalIcon />
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content reject-reason">
            <p class="my-0">El comprobante fue rechazado por: {{ rejectReason }}</p>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { ModalMixin, CloseModalIcon } from "wize-admin";
import { required } from "vuelidate/lib/validators";

export default {
  name: "WarningDeleteModal",
  props:{
    rejectReason: String
  },
  data() {
    return {
      reasonId: null,
      reasons: [],
    };
  },
  mixins: [ModalMixin],
  components: {
    CloseModalIcon
  },
  methods: {
    confirm() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      this.$emit("confirm");
    },
    close() {
      this.$emit("hide");
    },
  },
  validations: {
    reasonId: { required },
  },
};
</script>

<style lang="stylus" scoped>
</style>
