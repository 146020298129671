import VouchersView from "../VouchersView.vue";
import VoucherList from "../Pages/VoucherList.vue";

const routes = [
  {
    path: '/vouchers',
    component: VouchersView,
    children: [
      {
        path: 'list',
        name: 'voucher-list',
        roles: ['accounting'],
        component: VoucherList
      },
    ]
  },
]

export default routes
