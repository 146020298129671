<template>
  <div class="page-container payments-list">
    <div class="page-title-container">
      <h1 class="page-title">Pagos y comisiones</h1>
    </div>

    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar pagos</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <section class="d-flex align-items-center">
          <ButtonWithTooltip
            text="Actualizar"
            tooltip-variant="refresh-tooltip"
            @click="loadData"
          >
            <template slot="icon">
              <RefreshTableIcon />
            </template>
          </ButtonWithTooltip>

          <DropdownTable>
            <template v-slot:dropdown-icon>
              <DownloadTableIcon />
            </template>

            <template v-slot:dropdown-content>
              <DropdownItem text="Descargar excel" @click="downloadReport" />
              <DropdownItem
                text="Descargar Macro de abonos"
                @click="downloadMacroBank"
              />
            </template>
          </DropdownTable>

          <SearchBar
            placeholder="Buscar por  RUC, razón social, número de factura"
            @search="onSearch"
            class="search-bar"
          />
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th>
                <div class="d-flex justify-content-center align-items-center">
                  <span class="d-inline-block pt-1">Id de pago</span>
                </div>
              </th>
              <th>Fecha de registro</th>
              <th>RUC</th>
              <th>Razón social</th>
              <th>Monto facturado</th>
              <th>Monto abonado</th>
              <th>
                Fecha <br />
                de pago
              </th>
              <th>
                Factura <br />
                XML
              </th>
              <th>
                Factura <br />
                PDF
              </th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="cell-center">{{ o.code }}</td>
              <td class="cell-center" v-local-date="o.registerDate"></td>
              <td class="cell-center">{{ o.companyId }}</td>
              <td class="cell-center">{{ o.companyName }}</td>
              <td class="cell-center" v-decimal:pen="o.amount"></td>
              <td class="cell-center" v-decimal:pen="o.payedAmount"></td>
              <td class="cell-center" v-local-date="o.paymentDate"></td>
              <td class="cell-center">
                <b-button
                  v-if="o.voucher && o.voucher.xmlAsset"
                  type="none"
                  class="bg-transparent p-0 border-0"
                  @click="download(o.voucher.xmlAsset)"
                >
                  <XmlIcon />
                </b-button>
              </td>
              <td class="cell-center">
                <b-button
                  v-if="o.voucher && o.voucher.pdfAsset"
                  type="none"
                  class="bg-transparent p-0 border-0"
                  @click="download(o.voucher.pdfAsset)"
                >
                  <PdfIcon />
                </b-button>
              </td>
              <td class="cell-center pl-md-3">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>

              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable v-if="o.status === PaymentStatus.REJECTED.key">
                  <template v-slot:dropdown-content>
                    <!--                  <DropdownItem text="Marcar como pagado" @click="changeStatus(o.id, {status: PaymentStatus.PAID.key})"/>-->
                    <DropdownItem
                      text="Ver motivo de rechazo"
                      @click="seeRejectReason(o.rejectReason)"
                      v-if="o.status === PaymentStatus.REJECTED.key"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
    <RejectReasonModal
      :show="showRejectReasonModal"
      @hide="showRejectReasonModal = false"
      :reject-reason="selectedRejectReason"
    />
  </div>
</template>

<script>
import { Constants as LocalConstants } from "@/core/utils";
import { RejectReasonModal } from "@/core/components";
import {
  PaymentService,
  ProjectTypeService,
  ZonalService,
} from "@/core/services";
import fileDownload from "js-file-download";

import {
  Alert,
  Constants,
  Util,
  DeleteFilterIcon,
  FilterMenu,
  FilterMixin,
  FiltersIcon,
  PaginationMixin,
  DropdownItem,
  SearchBar,
  ButtonWithTooltip,
  RefreshTableIcon,
  PdfIcon,
  XmlIcon,
  DownloadTableIcon,
  DropdownTable
} from "wize-admin";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
    ButtonWithTooltip,
    RejectReasonModal,
    RefreshTableIcon,
    PdfIcon,
    XmlIcon,
    DownloadTableIcon
  },
  data() {
    return {
      list: [],
      PaymentStatus: LocalConstants.PaymentStatus,
      showRejectReasonModal: false,
      selectedRejectReason: null,
      tooltipType: null,
      filterOptions: [
        {
          label: "Factura",
          key: "hasVoucher",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.BooleanTypes.YES,
            LocalConstants.BooleanTypes.NO,
          ],
        },
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.PaymentStatus.PENDING,
            LocalConstants.PaymentStatus.REVIEW,
            LocalConstants.PaymentStatus.APPROVED,
            LocalConstants.PaymentStatus.REJECTED,
            LocalConstants.PaymentStatus.PAID,
            LocalConstants.PaymentStatus.INCORRECT,
          ],
        },
        {
          label: "Tipo de proyecto",
          key: "projectTypeIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Zonal",
          key: "zonalIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Fecha de registro",
          key: "registerDate",
          type: Constants.TypeFilters.DATE,
        },
        {
          label: "Fecha de pago",
          key: "paymentDate",
          type: Constants.TypeFilters.DATE,
        },
      ],
    };
  },
  mixins: [FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "code,desc",
        };

        let filters = { ...this.filters };

        filters = {
          ...filters,
          ...Util.getDateRangeFilters(filters.registerDate, "register"),
        };
        delete filters.registerDate;

        filters = {
          ...filters,
          ...Util.getDateRangeFilters(filters.paymentDate, "payment"),
        };
        delete filters.paymentDate;

        const data = { search: this.search, ...filters };
        const response = await PaymentService.list(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PaymentStatus);
    },
    download(asset) {
      if (asset) Util.downloadFile(asset.url, asset.name);
    },
    changeStatus(paymentId, data) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del pago?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await PaymentService.changeStatus(paymentId, data))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadMacroBank() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PaymentService.downloadMacroBank(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    seeRejectReason(rejectReason) {
      this.selectedRejectReason = rejectReason;
      this.showRejectReasonModal = true;
    },
  },
  async mounted() {
    await this.loadData(this.page, this.pageSize);
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
    const zonalList = (await ZonalService.listAll()).payload;
    const zonalOption = this.filterOptions.find((o) => o.key === "zonalIds");
    zonalList.forEach((b) =>
      zonalOption.options.push({ key: b.id, label: b.description })
    );

    const projectTypes = (await ProjectTypeService.listAll()).payload;
    const projectTypeOption = this.filterOptions.find(
      (o) => o.key === "projectTypeIds"
    );
    projectTypes.forEach((b) =>
      projectTypeOption.options.push({ key: b.id, label: b.description })
    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/payments-list';
</style>
