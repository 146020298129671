<template>
  <div class="page-container project-type-editor">
    <div class="page-title-container">
      <h1 class="page-title">{{ editing ? "Editar tipo de proyecto" : "Nuevo tipo de proyecto" }}</h1>
    </div>
    <div class="container px-0">
      <div class="row justify-content-center">
        <div class="col-12">
          <b-card
              tag="article"
              no-body
              style="max-width: 640px"
              class="custom-card"
          >
            <b-card-header
                header-tag="section"
                class="custom-card-header card-header d-flex align-items-center"
            >
              <h3>{{ editing ? "Datos del tipo de proyecto" : "Datos del nuevo tipo de proyecto" }} </h3>
            </b-card-header>
            <b-card-body>
              <form
                  class="col-12 custom-form custom-form--pusher"
                  @submit.prevent="save"
                  novalidate
              >
                <section class="form-content">
                  <div class="form-group">
                    <label for="description">Descripción *</label>
                    <input
                        id="description"
                        type="text"
                        v-model.trim="form.description"
                        autocomplete="off"
                        maxlength="100"
                        placeholder="Ingresar descripción"
                        class="custom-form-control"
                        :class="{
                        'custom-form-control-error':
                          $v.form.description.$error
                      }"
                    />
                    <FormError
                        v-if="
                        $v.form.description.$error &&
                        !$v.form.description.required
                      "
                        message="Descripción es requerida"
                    />
                  </div>
                  <div class="form-group">
                    <label for="prefix">Prefijo *</label>
                    <input
                      id="prefix"
                      type="text"
                      v-model.trim="form.prefix"
                      autocomplete="off"
                      maxlength="5"
                      placeholder="Ingresar prefijo"
                      class="custom-form-control"
                      :class="{
                        'custom-form-control-error':
                          $v.form.prefix.$error
                      }"
                    />
                    <FormError
                      v-if="
                        $v.form.prefix.$error &&
                        !$v.form.description.required
                      "
                      message="Prefijo es requerido"
                    />
                  </div>
                  <div class="form-group-buttons text-right">
                    <router-link
                        :to="{ name: 'config-project-type-list' }"
                        class="button button-light"
                    >
                      {{ editing ? 'Volver' : 'Cancelar' }}
                    </router-link>
                    <button class="button button-primary" type="submit">
                      Guardar
                    </button>
                  </div>
                </section>
              </form>
            </b-card-body>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {FormError, Alert} from "wize-admin"

import {required} from "vuelidate/lib/validators";
import {ProjectTypeService} from "@/core/services";

export default {
  components: {
    FormError,
  },
  data() {
    return {
      form: {
        description: null,
        prefix: null,
      },
      editing: false
    };
  },
  watch: {
    "$route.params.id": function () {
      if (this.$route.params.id) this.load(this.$route.params.id);
    },
  },
  methods: {
    async save() {
      const self = this;
      self.$v.$touch();
      if (self.$v.$error) return;

      try {
        this.$store.dispatch("app/loading", true);
        const data = {...this.form};
        let response;
        if (!this.editing) response = await ProjectTypeService.save(data);
        else
          response = await ProjectTypeService.update(
              this.$route.params.id,
              data
          );
        Alert.success("Se almacenó la información correctamente");
        if (!this.editing) {
          this.$router.push({
            name: "config-project-type-edit",
            params: {id: response.payload.id},
          });
          this.editing = true;
        }
      } catch (ex) {
        console.error(ex);
        Alert.error(ex.errors.message);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async load(id) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await ProjectTypeService.get(id);
        const payload = response.payload;
        this.form = {...payload};
        this.editing = true;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({name: "config-project-type-list"});
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  validations: {
    form: {
      description: {required},
      prefix: {required},
    },
  },
  mounted(){
    if (this.$route.params.id) this.load(this.$route.params.id);
  }
};
</script>

<style lang="stylus" scoped>
@import "../Styles/project-type-editor"
</style>
