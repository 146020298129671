<template>
  <div class="page-container pushers-list">
    <div class="page-title-container">
      <h1 class="page-title">Pushers</h1>
    </div>
    <div class="table-container">
      <div
        class="table-header d-flex flex-column flex-md-row justify-content-between align-items-start"
      >
        <div>
          <div
            class="d-flex align-items-center justify-content-between filter"
            @click="showFilters = !showFilters"
          >
            <FiltersIcon />

            <div>Filtrar pushers</div>
          </div>
        </div>

        <div class="d-flex flex-wrap align-items-center w-100 ml-2">
          <span
            v-for="(val, name) in filters"
            v-bind:key="name"
            class="d-flex justify-content-between mb-1 ml-2 filter-secondary"
          >
            {{ getFilterName(name, val) }}

            <DeleteFilterIcon @click="deleteFilter(name)" />
          </span>
        </div>

        <filter-menu
          :show="showFilters"
          @hide="showFilters = false"
          :options="filterOptions"
          :selectedOptionFilter="filters"
          @setFilters="setFilters"
        ></filter-menu>

        <!-- Otras opciones -->
        <section class="d-flex align-items-center">
          <ButtonWithTooltip
            text="Descargar"
            tooltip-variant="refresh-tooltip"
            @click="downloadReport"
          >
            <template slot="icon">
              <DownloadTableIcon />
            </template>
          </ButtonWithTooltip>
          <SearchBar
            placeholder="Buscar por n° de documento, nombres y apellidos, celular, correo electrónico, serie de huellero"
            @search="onSearch"
            class="search-bar"
          />

          <b-button
            :to="{ name: 'config-pusher-create' }"
            variant="none"
            class="button button-primary button-table-action"
            >Agregar
          </b-button>
        </section>
      </div>
      <div class="mb-0">
        <table class="custom-table">
          <thead>
            <tr>
              <th class="pl-md-3">
                <div class="d-flex justify-content-center align-items-center">
                  Nombres y apellidos
                </div>
              </th>
              <th>Tipo de documento</th>
              <th>Número de documento</th>
              <th>Celular</th>
              <th>Tipo de proyecto</th>
              <th>Zonal</th>
              <th>Fin de contrato</th>
              <th>Huellero</th>
              <th>Estado</th>
              <th class="pr-md-3">Opciones</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in list" :key="o.id">
              <td class="td-name pl-3">{{ o.firstName }} {{ o.lastName }}</td>
              <td class="cell-center">
                {{ getTypeDocument(o.identifierType).label }}
              </td>
              <td class="cell-center">{{ o.identifier }}</td>
              <td class="cell-center">{{ o.mobile }}</td>
              <td class="cell-center">
                {{ o.projectType != null ? o.projectType.description : null }}
              </td>
              <td class="cell-center">
                {{ o.zonal != null ? o.zonal.description : null }}
              </td>
              <td class="cell-center" v-local-date="o.contractFinishDate"></td>
              <td class="cell-center pl-md-2">
                {{ o.fingerSensor ? "SÍ" : "NO" }}
              </td>
              <td class="cell-center pl-md-2">
                <span :class="`badge ${getStatus(o.status).color}`">{{
                  getStatus(o.status).label
                }}</span>
              </td>
              <td class="cell-center d_option pr-md-2 position-relative">
                <DropdownTable>
                  <template slot="dropdown-content">
                    <DropdownItem
                      text="Ver datos"
                      :to="{
                        name: 'config-pusher-details',
                        params: { pusherId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Editar"
                      :to="{
                        name: 'config-pusher-edit',
                        params: { pusherId: o.id },
                      }"
                    />
                    <DropdownItem
                      text="Suspender"
                      @click="changeStatus(o.id, PushersStatus.INACTIVE.key)"
                      v-if="o.status === PushersStatus.ACTIVE.key"
                    />
                    <DropdownItem
                      text="Activar"
                      @click="changeStatus(o.id, PushersStatus.ACTIVE.key)"
                      v-if="o.status === PushersStatus.INACTIVE.key"
                    />
                    <DropdownItem
                      text="Descargar contrato"
                      @click="download(o.contract.asset)"
                      v-if="o.contract && o.contract.asset"
                    />
                    <DropdownItem
                      text="Descargar comodato"
                      @click="download(o.loan.asset)"
                      v-if="o.loan && o.loan.asset"
                    />
                  </template>
                </DropdownTable>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer d-flex justify-content-end align-items-center">
        <pagination
          :total="total"
          :page="page"
          :page-size="pageSize"
          :pages="pages"
          :changePage="changePage"
          :changePageSize="changePageSize"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Constants, Util, Alert, PaginationMixin, DropdownTable, DropdownItem, SearchBar,
  ButtonWithTooltip, DownloadTableIcon} from "wize-admin";
import { Constants as LocalConstants } from "@/core/utils";
import {
  ZonalService,
  ProjectTypeService,
  PusherService,
} from "@/core/services";

import { FilterMenu, FiltersIcon, DeleteFilterIcon, FilterMixin } from "wize-admin"
import fileDownload from "js-file-download";

export default {
  components: {
    SearchBar,
    DropdownTable,
    DropdownItem,
    FilterMenu,
    FiltersIcon,
    DeleteFilterIcon,
    ButtonWithTooltip,
    DownloadTableIcon
  },
  data() {
    return {
      list: [],
      PushersStatus: LocalConstants.PushersStatus,
      tooltipType: null,
      filterOptions: [
        {
          label: "Estado",
          key: "status",
          type: Constants.TypeFilters.SINGLE,
          options: [
            LocalConstants.PushersStatus.ACTIVE,
            LocalConstants.PushersStatus.INACTIVE,
          ],
        },
        {
          label: "Tipo de documento",
          key: "identifierTypes",
          type: Constants.TypeFilters.MULTIPLE,
          options: [
            LocalConstants.IdentifierType.DNI,
            LocalConstants.IdentifierType.CE,
            LocalConstants.IdentifierType.PASAPORTE,
            LocalConstants.IdentifierType.CARNE_REFUGIO,
            LocalConstants.IdentifierType.CARNE_IDENTIDAD,
            LocalConstants.IdentifierType.CARNE_PERMANENCIA,
            LocalConstants.IdentifierType.DNI_EXTRANJERO,
          ],
        },
        {
          label: "Fecha de incorporación",
          key: "admissionDate",
          type: Constants.TypeFilters.DATE
        },
        {
          label: "Fecha de finalización de contrato",
          key: "contractFinishDate",
          type: Constants.TypeFilters.DATE
        },
        {
          label: "Huellero",
          key: "fingerSensor",
          type: Constants.TypeFilters.SINGLE,
          options: [LocalConstants.BooleanTypes.YES, LocalConstants.BooleanTypes.NO],
        },
        {
          label: "Tipo de proyecto",
          key: "projectTypeIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
        {
          label: "Zonal",
          key: "zonalIds",
          type: Constants.TypeFilters.MULTIPLE,
          options: [],
        },
      ],
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    async loadData() {
      try {
        await this.$store.dispatch("app/loading", true);

        let filters = {...this.filters}
        filters = {...filters, ...Util.getDateRangeFilters(filters.admissionDate, 'admission')};
        delete filters.admissionDate;

        filters = {...filters, ...Util.getDateRangeFilters(filters.contractFinishDate, 'contractFinish')};
        delete filters.contractFinishDate

        const params = {
          page: this.page - 1,
          size: this.pageSize,
          sort: "firstName,lastName",
        };
        const data = { search: this.search, ...filters };
        const response = await PusherService.list(data, params);
        this.list = response.payload.content;
        this.total = response.payload.totalElements;
        this.pages = response.payload.totalPages;
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    getStatus(key) {
      return Util.searchValue(key, LocalConstants.PushersStatus);
    },
    getTypeDocument(key) {
      return Util.searchValue(key, LocalConstants.IdentifierType);
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    changeStatus(pusherId, status) {
      const self = this;
      Alert.dialog(
        "¿Desea cambiar el estado del pusher?",
        null,
        async function () {
          try {
            await self.$store.dispatch("app/loading", true);
            const resp = (await PusherService.changeStatus(pusherId, status))
              .payload;

            const obj = self.list.find((o) => {
              return o.id === resp.id;
            });
            if (obj) {
              const index = self.list.indexOf(obj);
              self.$set(self.list, index, resp);
            }
            Alert.success("Estado actualizado correctamente");
          } catch (e) {
            console.error(e);
            Alert.error(e.errors.message);
          } finally {
            await self.$store.dispatch("app/loading", false);
          }
        }
      );
    },
    async recoverPassword(pusherId) {
      try {
        await this.$store.dispatch("app/loading", true);
        await PusherService.recoverPassword(pusherId);
        Alert.success(
          "Se ha enviado un correo electrónico al pusher para que pueda restablecer su contraseña"
        );
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
    async downloadReport() {
      try {
        await this.$store.dispatch("app/loading", true);
        const data = { search: this.search, ...this.filters };
        const response = await PusherService.download(data);
        fileDownload(response.data, Util.getFileName(response));
      } catch (error) {
        console.error(error);
        Alert.error(error);
      } finally {
        await this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    await this.loadData(this.page, this.pageSize);
    const zonalList = (await ZonalService.listAll()).payload;
    const zonalOption = this.filterOptions.find((o) => o.key === "zonalIds");
    zonalList.forEach((b) =>
      zonalOption.options.push({ key: b.id, label: b.description })
    );

    const projectTypes = (await ProjectTypeService.listAll()).payload;
    const projectTypeOption = this.filterOptions.find(
      (o) => o.key === "projectTypeIds"
    );
    projectTypes.forEach((b) =>
      projectTypeOption.options.push({ key: b.id, label: b.description })
    );
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/pushers-list';
</style>
