<template>
  <b-modal
      v-model="showLocal"
      @hidden="hide"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      hide-header
      id="rejection-modal"
  >
    <div class="row">
      <div class="col-md-12">
        <section>
          <div
              class="
              modal-header
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div class="modal-title modal-title--rejection-modal text-white">Motivo de rechazo</div>
            <b-button
                class="icon-close icon-close-primary"
                @click="hideModal"
                aria-label="Close"
            >
            </b-button>
          </div>
        </section>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-center">
        <section class="modal-main-content">
          <info-badge
              text="Por favor para rechazar un comprobante debe seleccionar el motivo de rechazo"
          >
            <template slot="icon">
              <WarningAlertIcon class="mr-2 alert-icon" />
            </template>
          </info-badge>

          <form action="" @submit.prevent="confirm" class="custom-form" novalidate>
            <div
                class="
                form-group
                d-flex
                flex-column flex-md-row
                align-items-md-center
                mt-4
                mb-0
              "
            >
              <label for="rejectReason" class="pt-2">Motivo de rechazo</label>
              <FormSelect
                id="rejectReason"
                v-model="rejectReason"
                :items="rejectReasons"
                defaultOption="Elegir motivo de rechazo"
                :showError="
                  $v.rejectReason.$error && !$v.rejectReason.required
                "
              />
            </div>

            <div class="error-container text-left">
              <FormError
                  v-if="$v.rejectReason.$error && !$v.rejectReason.required"
                  message="Motivo de rechazo es requerido"
              />
            </div>

            <div class="form-group-buttons text-right">
              <button
                  class="button button-light"
                  type="button"
                  @click.prevent="hideModal()"
              >
                Cancelar
              </button>
              <button class="button button-primary" type="submit">
                Guardar
              </button>
            </div>
          </form>
        </section>
      </div>
    </div>
  </b-modal>
</template>
<script>

import {ModalMixin, FormError, FormSelect, InfoBadge, WarningAlertIcon} from "wize-admin";
import {required} from "vuelidate/lib/validators";
import {Constants as LocalConstants} from "@/core/utils";

export default {
  name: "ReasonForRejectionModal",
  props: {
    rejectReasons: Array,
    paymentId: Number
  },
  data() {
    return {
      rejectReason: null,
    };
  },
  mixins: [ModalMixin],
  validations: {
    rejectReason: {required},
  },
  components: {
    InfoBadge,
    FormError,
    FormSelect,
    WarningAlertIcon
  },
  methods: {
    confirm() {
      this.$v.$touch();
      if (this.$v.$error) return;
      const rejectReason = this.rejectReasons.find(o => o.key === this.rejectReason);
      this.$emit("confirm", this.paymentId, {
        status: LocalConstants.PaymentStatus.REJECTED.key,
        rejectReason: rejectReason.label
      });
      this.hideModal();
    },
    hideModal(){
      this.rejectReason = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="stylus">
</style>
