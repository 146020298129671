<template>
  <div class="page-container">
    <div class="page-title-container">
      <h1 class="page-title">Datos del pusher</h1>
    </div>

    <div class="px-0">
      <div class="row justify-content-center">
        <div class="col">
          <b-card tag="article" class="data-card" no-body>
            <section class="section">
              <h2 class="subtitle">Datos personales</h2>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Nombres</h3>
                    <p class="text">
                      {{ pusher.firstName }}
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Apellidos</h3>
                    <p class="text">
                      {{ pusher.lastName }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Tipo de documento</h3>
                    <p class="text">
                      {{ getIdentifierType(pusher.identifierType).label }}
                    </p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Número de documento</h3>
                    <p class="text">
                      {{ pusher.identifier }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h3 class="label">Dirección domiciliaria</h3>
                    <p class="text">
                      {{ pusher.address }}
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Celular</h3>
                    <p class="text" v-empty="pusher.mobile"></p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Correo electrónico</h3>
                    <p class="text" v-empty="pusher.email"></p>
                  </div>
                </div>

                <div class="row mb-0">
                  <div class="col-md-5">
                    <h3 class="label">Tipo de proyecto</h3>
                    <p
                      class="text"
                      v-empty="
                        pusher.projectType
                          ? pusher.projectType.description
                          : null
                      "
                    ></p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Zonal</h3>
                    <p
                      class="text"
                      v-empty="pusher.zonal ? pusher.zonal.description : null"
                    ></p>
                  </div>
                </div>
              </div>
            </section>

            <section class="section">
              <h2 class="subtitle">Datos de facturación</h2>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">RUC</h3>
                    <p
                      class="text"
                      v-empty="
                        pusher.supplier ? pusher.supplier.companyId : null
                      "
                    ></p>
                  </div>
                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">Razón social</h3>
                    <p
                      class="text"
                      v-empty="
                        pusher.supplier ? pusher.supplier.companyName : null
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </section>

            <section class="section border-bottom-0">
              <h2 class="subtitle">Datos del contrato</h2>

              <div class="container-fluid">
                <div class="row">
                  <div class="col-md-5">
                    <h3 class="label">Fecha de incorporación</h3>
                    <p class="text" v-local-date="pusher.admissionDate"></p>
                  </div>

                  <div class="col-md-6 mt-4 mt-md-0">
                    <h3 class="label">
                      Fecha de finalización del último contrato
                    </h3>
                    <p
                      class="text"
                      v-local-date="pusher.contractFinishDate"
                    ></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <h3 class="label">Descargar contrato</h3>
                    <b-button
                      variant="none"
                      class="button button-primary"
                      v-if="pusher.contract && pusher.contract.asset"
                      @click="download(pusher.contract.asset)"
                    >
                      Descargar
                    </b-button>
                    <InfoBadge
                      v-else
                      text="El pusher no cuenta con contrato adjunto."
                      class="data-alert"
                    />
                  </div>
                </div>

                <div class="row mb-0">
                  <div class="col-md-5 mb-3">
                    <h3 class="label">Cuenta con huellero</h3>
                    <p class="text">{{ pusher.fingerSensor ? "Sí" : "No" }}</p>
                  </div>
                </div>

                <div class="row" v-if="pusher.fingerSensor">
                  <div class="col-md-5">
                    <h3 class="label">Serie de huellero</h3>
                    <p class="text">
                      {{ pusher.equipmentSerial }}
                    </p>
                  </div>

                  <div class="col-md-6" v-if="pusher.fingerSensor">
                    <h3 class="label">Modelo de huellero</h3>
                    <p class="text" v-empty="pusher.equipmentModel"></p>
                  </div>
                </div>

                <div class="row" v-if="pusher.fingerSensor">
                  <div class="col-md-5">
                    <h3 class="label">Fecha de entrega</h3>
                    <p
                      class="text"
                      v-local-date="pusher.equipmentAssigmentDate"
                    ></p>
                  </div>

                  <div class="col-md-6" v-if="pusher.fingerSensor">
                    <h3 class="label">Fecha de firma de comodato</h3>
                    <p
                      class="text"
                      v-local-date="pusher.equipmentSignatureDate"
                    ></p>
                  </div>
                </div>

                <div class="row mb-0" v-if="pusher.fingerSensor">
                  <div class="col-md-6">
                    <h3 class="label">Descargar contrato de comodato</h3>
                    <b-button
                      variant="none"
                      class="button button-primary"
                      v-if="pusher.loan && pusher.loan.asset"
                      @click="download(pusher.loan.asset)"
                      >Descargar
                    </b-button>

                    <InfoBadge
                      v-else
                      text="El pusher no cuenta con comodato adjunto."
                      class="data-alert"
                    />
                  </div>
                </div>
                <div class="row float-right mb-0">
                  <router-link
                    :to="{ name: 'config-pusher-list' }"
                    class="button button-light"
                  >
                    Volver
                  </router-link>
                </div>
              </div>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Util, Alert, InfoBadge } from "wize-admin";
import { PusherService } from "@/core/services";
import { Constants as LocalConstants } from "@/core/utils";

export default {
  data() {
    return {
      pusher: {},
    };
  },
  components: {
    InfoBadge,
  },
  methods: {
    getIdentifierType(key) {
      return Util.searchValue(key, LocalConstants.IdentifierType);
    },
    async load(pusherId) {
      try {
        this.$store.dispatch("app/loading", true);
        const response = await PusherService.get(pusherId);
        const payload = response.payload;
        this.pusher = { ...payload };
        if (payload.pusherEquipment) {
          this.pusher.equipmentAssigmentDate =
            payload.pusherEquipment.assigmentDate;
          this.pusher.equipmentSignatureDate =
            payload.pusherEquipment.signatureDate;
          if (payload.pusherEquipment.equipment) {
            const equipment = payload.pusherEquipment.equipment;
            this.pusher.equipmentSerial = equipment.serial;
            this.pusher.equipmentModel = equipment.model;
          }
        }
        this.$nextTick(() => {
          this.pusher.identifier = payload.identifier;
        });
      } catch (e) {
        console.error(e);
        Alert.error(e.errors.message);
        this.$router.push({ name: "config-pusher-list" });
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
    async download(asset) {
      try {
        if (asset) {
          this.$store.dispatch("app/loading", true);

          await Util.downloadFile(asset.url, asset.name);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.$store.dispatch("app/loading", false);
      }
    },
  },
  async created() {
    this.load(this.$route.params.pusherId);
  },
};
</script>

<style lang="stylus" scoped>
@import '../Styles/pushers-data';
</style>
