var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-container"},[_vm._m(0),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12 col-md-8"},[_c('b-card',{staticClass:"custom-card",staticStyle:{"max-width":"640px"},attrs:{"tag":"article","no-body":""}},[_c('b-card-header',{staticClass:"custom-card-header d-flex justify-content-between align-items-center",attrs:{"header-tag":"section"}},[_c('h3',[_vm._v("Carga de abonos")])]),_c('b-card-body',{staticClass:"custom-card-body"},[_c('info-badge',{attrs:{"text":"Seleccione los siguientes campos para poder cargar sus archivos."}},[_c('template',{slot:"icon"},[_c('WarningAlertIcon',{staticClass:"mr-2 alert-icon"})],1)],2),_c('form',{staticClass:"custom-form custom-form--payments payments-form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('div',{staticClass:"custom-form"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"operationNumber"}},[_vm._v("N° de operación *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.operationNumber),expression:"operationNumber",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error': _vm.$v.operationNumber.$error,
                    },attrs:{"id":"operationNumber","type":"text","autocomplete":"off","maxlength":"50","placeholder":"Número de operación"},domProps:{"value":(_vm.operationNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.operationNumber=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      _vm.$v.operationNumber.$error &&
                      !_vm.$v.operationNumber.required
                    )?_c('FormError',{attrs:{"message":"Número de operación es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"paymentDate"}},[_vm._v("Fecha de abono *")]),_c('FormDatepicker',{attrs:{"label-id":"paymentDate","show-calendar":_vm.showPaymentDate,"max":new Date()},on:{"show":function($event){_vm.showPaymentDate = true},"hidden":function($event){_vm.showPaymentDate = false}},model:{value:(_vm.paymentDate),callback:function ($$v) {_vm.paymentDate=$$v},expression:"paymentDate"}}),(
                      _vm.$v.paymentDate.$error &&
                      !_vm.$v.paymentDate.required
                    )?_c('FormError',{attrs:{"message":"Fecha de abono es requerida"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"payrollNumber"}},[_vm._v("N° de planilla *")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.payrollNumber),expression:"payrollNumber",modifiers:{"trim":true}}],staticClass:"custom-form-control",class:{
                      'custom-form-control-error': _vm.$v.payrollNumber.$error,
                    },attrs:{"id":"payrollNumber","type":"text","autocomplete":"off","maxlength":"50","placeholder":"N° de planilla"},domProps:{"value":(_vm.payrollNumber)},on:{"input":function($event){if($event.target.composing){ return; }_vm.payrollNumber=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      _vm.$v.payrollNumber.$error && !_vm.$v.payrollNumber.required
                    )?_c('FormError',{attrs:{"message":"Número de planilla es requerido"}}):_vm._e()],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"payrollDesc"}},[_vm._v("Descripción de planilla")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.payrollDesc),expression:"payrollDesc",modifiers:{"trim":true}}],staticClass:"custom-form-control",attrs:{"id":"payrollDesc","type":"text","autocomplete":"off","maxlength":"100","placeholder":"Descripción de planilla"},domProps:{"value":(_vm.payrollDesc)},on:{"input":function($event){if($event.target.composing){ return; }_vm.payrollDesc=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('section',{staticClass:"file-container"},[_c('label',{attrs:{"for":"file"}},[_vm._v("Cargar excel de abonos *")]),_c('FileInput',{staticClass:"w-100 mt-2",attrs:{"id-file":"file","acceptFile":['.xlsx']},on:{"onChange":_vm.onFileChange}}),(_vm.$v.file.$error && !_vm.$v.file.required)?_c('FormError',{attrs:{"message":"El archivo es requerido"}}):_vm._e()],1),_c('section',{staticClass:"file-container"},[_c('label',{attrs:{"for":"records"}},[_vm._v("Cargar constancias")]),_c('FileInput',{staticClass:"w-100 mt-2",attrs:{"multiple":true,"id-file":"records","acceptFile":['.xlsx', '.pdf']},on:{"onChange":_vm.onRecordsChange}})],1),_c('div',{staticClass:"form-group-buttons text-right"},[_c('router-link',{staticClass:"button button-light",attrs:{"to":{ name: 'payments-made-batch-list' }}},[_vm._v(" Cancelar ")]),_c('button',{staticClass:"button button-primary",attrs:{"type":"submit"}},[_vm._v(" Procesar ")])],1),_c('div',{staticClass:"form-errors mt-3"},[_c('b',[(_vm.errors.length > 0)?_c('span',{staticClass:"error"},[_vm._v("Errores: "+_vm._s(_vm.errors.length))]):_vm._e()]),_c('br'),_vm._l((_vm.errors),function(e,i){return _c('span',{key:(i + "-error"),staticClass:"file-error"},[_vm._v(_vm._s(e)),_c('br')])})],2)])],1)],1)],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-title-container"},[_c('h1',{staticClass:"page-title"},[_vm._v("Cargar abonos")])])}]

export { render, staticRenderFns }